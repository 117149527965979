import { defineStore, storeToRefs } from "pinia";
import { watch, ref } from "vue";
import { useStorage } from "@vueuse/core";
import { useNotificationStore } from "@/stores/notification";
import { diffInDays, usagePeriodLeft, formatDateLocal } from "@/helpers";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/billing/";

export const useBillingStore = defineStore("billing", () => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const { user } = storeToRefs(authStore);
  const notificationStore = useNotificationStore();
  const { notificationTurnedOff } = storeToRefs(notificationStore);
  const paymentMethodNeededBy = useStorage("paymentMethodNeededBy", "");
  const billingInfo = ref<any>({});

  // watch(paymentMethodNeededBy, (date) => {
  //   if (date) {
  //     const leftDays = diffInDays(date);
  //     const title = !usagePeriodLeft(date)
  //       ? "Subscription Expired"
  //       : leftDays > 0
  //       ? `Expires in ${leftDays} days`
  //       : "Expires Today";
  //     const message = !usagePeriodLeft(date)
  //       ? "Your subscription has expired. To keep using room360, please set up your payment method in Billing Menu."
  //       : `Your next billing is at ${formatDateLocal(
  //           date
  //         )}. To keep using rooom360 after that, please set up your payment method in Billing menu.`;
  //     notificationStore.openNotification({
  //       type: "warning",
  //       title: title,
  //       message: message,
  //       dismissText: "Later",
  //       linkText: "Go To Billing",
  //       linkAction: "/user/billing",
  //     });
  //   }
  // });

  function developmentReset() {
    return axios
      .delete(API_URL + "development-reset", authStore.authRequestConfig())
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        userStore.handleUserError(error);
      });
  }

  function createCheckoutSession(
    redirectPage?: string,
    newlySubscribingPlan?: any
  ) {
    const payload: any = {
      redirectPath: redirectPage ? redirectPage : "/user/dashboard",
    };
    if (newlySubscribingPlan) {
      payload["newlySubscribingPlan"] = newlySubscribingPlan;
    }
    return axios
      .post(
        API_URL + "payment/checkout",
        payload,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        userStore.handleUserError(error);
      });
  }

  function fetchCurrentBilling() {
    return axios
      .get(API_URL + "subscription", authStore.authRequestConfig())
      .then((response) => {
        if (response.data) {
          billingInfo.value = {
            status: response.data.status,
            nextBillingDate: response.data.nextBillingDate,
            price: parseInt(response.data.price),
            PRICE_PER_SHOWCASE: parseInt(response.data.PRICE_PER_SHOWCASE),
            PRICE_PER_SCAN_POINT: parseInt(response.data.PRICE_PER_SCAN_POINT),
            PRICE_PER_GUIDED_TOUR: parseInt(
              response.data.PRICE_PER_GUIDED_TOUR
            ),
            paymentMethodSet: response.data.paymentMethodSet,
            numberOfShowcases: response.data.numberOfShowcases,
            numberOfScanPoints: response.data.numberOfScanPoints,
            showcaseProducts: response.data.showcaseProducts,
          };
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
        userStore.handleUserError(error);
      });
  }

  function getStripeApiKey() {
    return axios
      .get(API_URL + "stripe", authStore.authRequestConfig())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        userStore.handleUserError(error);
      });
  }

  function removeShowcaseSubscription(showcaseToken: string) {
    return axios
      .delete(
        API_URL + "subscription",
        authStore.authRequestConfig({
          data: { showcaseToken: showcaseToken },
        })
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        // TODO: recheck: handle 412 errors specifically
        if (error.response.status === 412) {
          return error.response;
        } else {
          userStore.handleUserError(error);
        }
      });
  }

  function addShowcaseSubscription(plan: any) {
    return axios.post(
      API_URL + "subscription",
      plan,
      authStore.authRequestConfig()
    );
  }

  function getPaymentMethod() {
    return axios
      .get(API_URL + "payment-method", authStore.authRequestConfig())
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        userStore.handleUserError(error);
      });
  }

  return {
    paymentMethodNeededBy,
    developmentReset,
    createCheckoutSession,
    fetchCurrentBilling,
    getStripeApiKey,
    removeShowcaseSubscription,
    addShowcaseSubscription,
    getPaymentMethod,
    billingInfo,
  };
});
