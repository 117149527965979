import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/LoginPage.vue";
import Register from "../views/RegisterPage.vue";
import AppLayout from "@/components/AppLayout.vue";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLayout,
    name: "Home",
    redirect: "/login",
    // meta: {
    //   title: "rooom360",
    // },
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
          title: "rooom360",
          disallowAuthed: true,
        },
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
          title: "rooom360",
        },
      },
      {
        path: "/register/:token",
        component: () => import("../views/ConfirmRegistrationPage.vue"),
        meta: {
          title: "Registration Confrimed | rooom360",
        },
      },
      {
        path: "/reset-password/:token",
        name: "ResetPassword",
        meta: { title: "Reset Password | rooom360" },
        component: () => import("../views/PasswordResetPage.vue"),
      },
      {
        name: "Privacy",
        path: "/privacy-policy",
        meta: { title: "Privacy Policy | rooom360" },
        component: () => import("@/views/PrivacyPage.vue"),
      },
      {
        name: "TermsAndConditions",
        path: "/terms-conditions",
        meta: { title: "Terms and Conditions | rooom360" },
        component: () => import("@/views/TermsPage.vue"),
      },
      {
        name: "Imprint",
        path: "/imprint",
        meta: { title: "Imprint | rooom360" },
        component: () => import("@/views/ImprintPage.vue"),
      },
      {
        name: "Disclaimer",
        path: "/disclaimer",
        meta: { title: "Disclaimer | rooom360" },
        component: () => import("@/views/DisclaimerPage.vue"),
      },
      {
        name: "Changelog",
        path: "/changelog",
        meta: { title: "Changelog | rooom360" },
        component: () => import("@/views/ChangelogPage.vue"),
      },
      {
        path: "/user",
        meta: { requiresAuth: true },
        children: [
          {
            name: "Dashboard",
            path: "dashboard",
            alias: "",
            meta: { title: "Dashboard | rooom360" },
            component: () => import("../views/DashboardPage.vue"),
          },
          {
            name: "Profile",
            path: "profile",
            meta: { title: "Profile | rooom360" },
            component: () => import("../views/ProfilePage.vue"),
          },
          {
            name: "Accounts",
            path: "accounts",
            meta: { title: "Accounts | rooom360" },
            component: () => import("../views/AccountsPage.vue"),
          },
          {
            name: "Billing",
            path: "billing",
            alias: "",
            meta: { title: "Billing | rooom360" },
            component: () => import("../views/billing-page/BillingPage.vue"),
          },
        ],
      },
      {
        path: "/showcase/:token/edit",
        name: "ShowcaseEditor",
        meta: {
          title: "Editor | rooom360",
          requiresAuth: true,
        },
        component: () =>
          // import("../views/ShowcaseEditor.vue"),
          import("../views/showcase-editor/ShowcaseEditorLayout.vue"),
      },
      {
        path: "/showcase/:token/view",
        name: "ShowcaseViewer",
        meta: {
          title: "Showcase | rooom360",
        },
        component: () => import("../views/ShowcaseViewerPage.vue"),
      },
      {
        path: "showcase/:token",
        name: "ShowcasePublicViewer",
        alias: "/:token",
        meta: {
          title: "Showcase | rooom360",
        },
        component: () => import("../views/ShowcaseViewerPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? (to.meta.title as string) : "rooom360";
  const authStore = useAuthStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authStore.user.id) {
      if (to.name === "ShowcaseEditor") {
        const token = to.params.token;
        next({
          path: "/" + token,
          query: { redirect: to.fullPath },
        });
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    } else {
      next();
    }
    // redirect to user page when logged in
  } else if (to.matched.some((record) => record.meta.disallowAuthed)) {
    if (authStore.user.id) {
      next({
        path: "/user/dashboard",
      });
    } else {
      next();
    }
    // next() should always be called
  } else {
    next();
  }
});

export default router;
