<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    v-if="user.id && open && currentPage !== 'Billing'"
    aria-live="assertive"
    class="z-40 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="max-w-sm w-full mt-14 bg-white dark:bg-gray-700 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <ExclamationCircleIcon
                  v-if="type === 'warning'"
                  class="h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                />
                <ArchiveBoxIcon
                  v-else
                  class="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900 dark:text-gray-50">
                  {{ title }}
                </p>
                <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {{ message }}
                </p>
                <div class="mt-3 flex space-x-7">
                  <button
                    @click.prevent="linkTextClicked"
                    type="button"
                    class="rounded-md text-sm font-medium text-rooom-light hover:text-opacity-80 focus:outline-none"
                  >
                    {{ linkText }}
                  </button>
                  <button
                    type="button"
                    @click.prevent="turnOff"
                    class="rounded-md text-sm font-medium text-gray-700 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200 focus:outline-none"
                  >
                    {{ dismissText }}
                  </button>
                </div>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click.prevent="close"
                  class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  ArchiveBoxIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { useNotificationStore } from "@/stores/notification";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "AppNotification",
  components: { ArchiveBoxIcon, ExclamationCircleIcon, XMarkIcon },
  setup() {
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const notificationStore = useNotificationStore();
    const {
      open,
      type,
      title,
      message,
      linkText,
      dismissText,
      linkAction,
      notificationTurnedOff,
    } = storeToRefs(notificationStore);
    const router = useRouter();

    function turnOff() {
      notificationStore.closeNotification();
      // TODO: check if this works correctly - would it ever reopen?
      notificationTurnedOff.value = true;
    }

    function close() {
      notificationStore.closeNotification();
    }

    const linkTextClicked = () => {
      notificationStore.closeNotification();
      router.push(linkAction.value);
    };

    const route = useRoute();
    const currentPage = route.name;

    return {
      close,
      open,
      type,
      title,
      message,
      linkText,
      dismissText,
      linkTextClicked,
      user,
      currentPage,
      turnOff,
    };
  },
});
</script>
