
import { defineComponent } from "vue";
import { useBannerStore } from "@/stores/banner";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "AppBanner",
  components: {
    XMarkIcon,
  },
  setup() {
    const bannerStore = useBannerStore();
    const { open, type, message, linkText, linkAction } =
      storeToRefs(bannerStore);
    const router = useRouter();
    const onLinkClick = () => {
      if (linkAction.value) {
        router.push(linkAction.value);
      }
    };
    const closeBanner = () => bannerStore.closeBanner();

    return {
      open,
      type,
      message,
      linkText,
      linkAction,
      onLinkClick,
      closeBanner,
    };
  },
});
