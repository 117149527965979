import { defineStore } from "pinia";
import { ref } from "vue";

interface WarningPanelPayload {
  type?: string;
  key: string;
  header: string;
  message: string;
  acceptText: string;
  dismissText?: string;
}

const DEFAULT_TYPE = "DELETE";
const DEFAULT_KEY = "";
const DEFAULT_HEADER = "";
const DEFAULT_MESSAGE = "";
const DEFAULT_ACCEPT_TEXT = "";
const DEFAULT_DISMISS_TEXT = "Cancel";
const DEFAULT_RESULT = "";

export const useWarningPanelStore = defineStore("warning_panel", () => {
  const open = ref(false);
  const type = ref(DEFAULT_TYPE);
  const key = ref(DEFAULT_KEY);
  const header = ref(DEFAULT_HEADER);
  const message = ref(DEFAULT_MESSAGE);
  const acceptText = ref(DEFAULT_ACCEPT_TEXT);
  const dismissText = ref(DEFAULT_DISMISS_TEXT);
  const result = ref(DEFAULT_RESULT);

  function openPanel(payload: WarningPanelPayload) {
    if (payload.type) {
      type.value = payload.type;
    }
    key.value = payload.key;
    header.value = payload.header;
    message.value = payload.message;
    acceptText.value = payload.acceptText;
    if (payload.dismissText) {
      dismissText.value = payload.dismissText;
    }
    open.value = true;
  }

  function accept() {
    open.value = false;
    result.value = "accepted";
  }

  function dismiss() {
    open.value = false;
    result.value = "dismissed";
  }

  function reset() {
    type.value = DEFAULT_TYPE;
    key.value = DEFAULT_KEY;
    header.value = DEFAULT_HEADER;
    message.value = DEFAULT_MESSAGE;
    acceptText.value = DEFAULT_ACCEPT_TEXT;
    dismissText.value = DEFAULT_DISMISS_TEXT;
    result.value = DEFAULT_RESULT;
  }

  return {
    open,
    type,
    key,
    header,
    message,
    acceptText,
    dismissText,
    result,
    openPanel,
    accept,
    dismiss,
    reset,
  };
});
