
import { defineComponent } from "vue";
import { useShowcaseStore } from "@/stores/showcase";

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    message: {
      type: String,
      default: "Loading...",
    },
  },
  setup() {
    const showcaseStore = useShowcaseStore();
    // turning this off because the loading spinner at the beginning of the showcase load gets turned off right away
    // might need to find an alternative way if it's needed elsewhere...
    // if (showcaseStore.loadingSpinner) {
    //   showcaseStore.closeLoadingSpinner();
    // }
  },
});
