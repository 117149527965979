
import { defineComponent, ref, computed } from "vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import AppAlert from "@/components/AppAlert.vue";
import AppBanner from "@/components/AppBanner.vue";
import AppNotification from "@/components/AppNotification.vue";
import DarkModeButton from "@/components/DarkModeButton.vue";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";
import { useShowcaseStore } from "@/stores/showcase";
import WarningConfirmationPanel from "@/components/WarningConfirmationPanel.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "AppLayout",
  components: {
    Bars3Icon,
    XMarkIcon,
    AppAlert,
    AppBanner,
    AppNotification,
    DarkModeButton,
    WarningConfirmationPanel,
    LoadingSpinner,
  },
  setup() {
    const showcaseStore = useShowcaseStore();
    const authStore = useAuthStore();
    const { user } = storeToRefs(authStore);
    const loggedIn = computed(() => {
      if (user.value.id) {
        return true;
      } else {
        return false;
      }
    });
    const open = ref(false);

    let currentDarkMode = localStorage.getItem("darkMode");
    if (currentDarkMode === null) {
      localStorage.setItem("darkMode", "false");
      currentDarkMode = "false";
    }
    const darkMode = ref(currentDarkMode === "true");

    function toggleDarkMode() {
      darkMode.value = !darkMode.value;
      localStorage.setItem("darkMode", darkMode.value.toString());
    }

    const navigation = computed(() => {
      const menu = [
        { name: "Dashboard", href: "/user/dashboard" },
        { name: "Profile", href: "/user/profile" },
      ];
      if (!["admin", "manager", "premium_user"].includes(user.value.role)) {
        menu.push({ name: "Billing", href: "/user/billing" });
      } else if (["admin", "manager"].includes(user.value.role)) {
        menu.push({ name: "Accounts", href: "/user/accounts" });
      }
      return menu;
    });

    function logout() {
      authStore.logout();
    }

    const { loadingSpinner } = storeToRefs(showcaseStore);

    return {
      open,
      user,
      navigation,
      logout,
      loggedIn,
      darkMode,
      toggleDarkMode,
      loadingSpinner,
    };
  },
});
