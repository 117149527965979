
import { ref, defineComponent } from "vue";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "ForgotPasswordPanel",
  setup(_, { emit }) {
    const authStore = useAuthStore();
    const email = ref("");
    const emailSent = ref(false);

    function onSend() {
      if (!email.value) return;
      authStore.registerForgotPassword(email.value).then((success: boolean) => {
        if (success) {
          emailSent.value = true;
        }
      });
    }

    function onResend() {
      email.value = "";
      emailSent.value = false;
    }

    function closePanel() {
      emit("closeForgotPasswordPanel");
      email.value = "";
      emailSent.value = false;
    }

    return {
      emailSent,
      email,
      onSend,
      onResend,
      closePanel,
    };
  },
});
