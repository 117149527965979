import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { AxiosError } from "axios";
import { useAlertStore } from "@/stores/alert";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import router from "@/router";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/showcase/";

export const useShowcaseStore = defineStore("showcase", () => {
  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const showcaseLocked = ref<undefined | boolean>(undefined);
  const loadingSpinner = ref("");

  const showcaseInfo = ref<any>({});
  const title = ref("");
  const tags = ref<any[]>([]);
  const mattertags = ref<any[]>([]);
  const objectsToBeMounted = ref<any[]>([]);
  const helperUiOn = ref(false);
  const showcaseToken = ref<string>();
  const objects = ref<any[]>([]);
  const components = ref<any>({});
  const numberOfObjectsToBeLoaded = ref(0);
  const initiating = ref(true);
  const objectsLoading = ref(true);
  const axisLockedState = ref<any>({
    translate: { x: false, y: true, z: false },
    scale: { x: false, y: false, z: false },
    rotate: { x: true, y: false, z: true },
  });

  const selectedObjectId = ref<string | number>(0);
  const selectedItem = ref<any>({
    transformValues: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { x: 0, y: 0, z: 0 },
      scale: { x: 0, y: 0, z: 0 },
    },
    data: {},
  });

  watch(numberOfObjectsToBeLoaded, (leftovers: number, oldLeftovers) => {
    if (oldLeftovers === 0 && leftovers > 0) {
      initiating.value = false;
      closeLoadingSpinner();
      objectsLoading.value = true;
    } else if (leftovers === 0) {
      setTimeout(() => {
        objectsLoading.value = false;
      }, 500);
    }
  });

  function handleShowcaseError(error: AxiosError) {
    if (loadingSpinner.value) {
      closeLoadingSpinner();
    }
    if (error.response?.status === 401) {
      closeLoadingSpinner();
      alertStore.openAlert({
        type: "error",
        message_key: "JWT_EXPIRED",
      });
      authStore.logout();
      router.push({
        name: "ShowcaseViewer",
        params: {
          token: showcaseToken.value,
        },
      });
    } else {
      // hide the unexpected server errors
      if (error.response) {
        alertStore.openAlert({
          type: "error",
          response: error.response,
        });
      }
    }
  }

  function openLoadingSpinner(message: string) {
    loadingSpinner.value = message;
  }

  function closeLoadingSpinner() {
    loadingSpinner.value = "";
  }

  async function getShowcase(showcaseToken: string) {
    return axios
      .get(API_URL + `${showcaseToken}`, authStore.authRequestConfig())
      .then((response) => {
        showcaseLocked.value = false;
        return response;
      })
      .catch(async (error) => {
        if ([403, 426].includes(error.response?.status)) {
          showcaseLocked.value = true;
          closeLoadingSpinner();
          return error.response;
        } else {
          handleShowcaseError(error);
        }
      });
  }

  async function getShowcaseEditor() {
    return axios
      .get(
        API_URL + `${showcaseToken.value}/editor`,
        authStore.authRequestConfig()
      )
      .then((response) => {
        showcaseLocked.value = false;
        showcaseInfo.value = response.data.showcase;
        title.value = response.data.showcase.title;
        objects.value = [...response.data.items];
        objects.value.forEach((object: any) => {
          if (object.visible === undefined) object.visible = true;
        });
        tags.value = [...response.data.tags];
        mattertags.value = [...response.data.mattertags];
        return response;
      })
      .catch(async (error) => {
        if (error.response.status == 426) {
          showcaseLocked.value = true;
          closeLoadingSpinner();
          return;
        } else {
          handleShowcaseError(error);
        }
      });
  }

  function getShowcaseViewer(showcaseToken: string) {
    return axios.get(API_URL + `${showcaseToken}/viewer`);
  }

  function getShowcaseViewerWhileLoggedIn(showcaseToken: string) {
    return axios.get(
      API_URL + `${showcaseToken}/viewer_logged_in`,
      authStore.authRequestConfig()
    );
  }

  function getShowcaseViewerWithPassword(showcaseToken: string, payload: any) {
    return axios.post(API_URL + `${showcaseToken}/viewer-password`, payload);
  }

  function getShowcaseMetadata(showcaseToken: string) {
    return axios
      .get(API_URL + `${showcaseToken}/metadata`, authStore.authRequestConfig())
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function editShowcaseMetadata(update: any) {
    return axios
      .put(
        API_URL + `${update.token}/metadata`,
        update,
        authStore.authRequestConfig()
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Successfully updated.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function updateShowcaseImage(update: any) {
    return axios
      .put(
        API_URL + `${update.token}/thumbnail?mode=${update.mode}`,
        update.payload,
        authStore.authRequestConfig()
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Successfully updated.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function addObject(object: any) {
    // TODO: change item to object in backend
    return axios
      .post(
        API_URL + `${object.showcaseToken}/item`,
        object,
        authStore.authRequestConfig()
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Successfully added.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  async function duplicateObject(object: any) {
    loadingSpinner.value = "Duplicating...";
    const copiedObject = { ...object };
    copiedObject.title = copiedObject.title + " copy";
    copiedObject.position[0] -= 1.2;
    copiedObject.visible = true;
    addObject(copiedObject).then((response: any) => {
      copiedObject.id = response.data.id;
      copiedObject.shadowUrl = response.data.shadowUrl;
      objectsToBeMounted.value = [...objectsToBeMounted.value, copiedObject];
      loadingSpinner.value = "";
    });
  }

  function removeObject(payload: any) {
    return axios
      .delete(
        API_URL + `${payload.showcaseToken}/item`,
        authStore.authRequestConfig({
          data: { id: payload.id },
        })
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Successfully removed.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function updateObject(update: any) {
    return axios
      .put(
        API_URL + `${update.showcaseToken}/item`,
        update,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function addTag(tag: any) {
    return axios
      .post(
        API_URL + `${tag.showcaseToken}/tag`,
        tag,
        authStore.authRequestConfig()
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Tag successfully added.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function addMattertags(payload: any) {
    return axios
      .post(
        API_URL + `${payload.showcaseToken}/mattertag`,
        payload.tags,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function removeTag(payload: any) {
    return axios
      .delete(
        API_URL + `${payload.showcaseToken}/tag`,
        authStore.authRequestConfig({
          data: { id: payload.tagId },
        })
      )
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Tag successfully removed.",
        });
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function updateTag(update: any) {
    return axios
      .put(
        API_URL + `${update.showcaseToken}/tag`,
        update.data,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function updateTagPosition(update: any) {
    return axios
      .put(
        API_URL + `${update.showcaseToken}/tag/position`,
        update.data,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  async function updateMattertag(update: any) {
    return axios
      .put(
        API_URL + `${update.showcaseToken}/mattertag`,
        update.data,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function uploadResource(upload: any) {
    return axios
      .put(
        API_URL + `${upload.showcaseToken}/resource`,
        upload.file,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleShowcaseError(error);
      });
  }

  function setShowcaseToken(token: string) {
    showcaseToken.value = token;
  }

  function setObjects(_objects: any[]) {
    objects.value = [..._objects];
    objects.value.forEach((object: any) => {
      if (object.visible === undefined) object.visible = true;
    });
  }

  function setNumberOfObjectsToBeLoaded(numberOfObjects: number) {
    numberOfObjectsToBeLoaded.value = numberOfObjects;
  }

  function turnOffInitiating() {
    initiating.value = false;
  }

  function setSelectedItem(item: any) {
    selectedItem.value = item;
  }

  function deselectItem() {
    selectedItem.value = {
      transformValues: {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
        scale: { x: 0, y: 0, z: 0 },
      },
      data: {},
    };
    //
  }

  function setObjectsToBeMounted(objs: any[]) {
    objectsToBeMounted.value = [...objs];
  }

  function turnOffObjectsLoading() {
    objectsLoading.value = false;
  }

  const currentTransformMode = ref("translate");

  function setTransformMode(mode: string) {
    currentTransformMode.value = mode;
  }

  const showcaseRenderer = ref<any>();

  function setShowcaseRenderer(renderer: any) {
    showcaseRenderer.value = renderer;
  }

  function getShowcaseRenderer() {
    return showcaseRenderer.value;
  }

  function setTags(newTags: any) {
    tags.value = [...newTags];
  }

  function tagGetter() {
    return tags.value;
  }

  const mattertagsToBeUpdated: any = ref([]);

  watch(
    () => mattertagsToBeUpdated.value,
    (_tags) => {
      _tags.forEach(async (_tag: any) => {
        const payload = {
          showcaseToken: showcaseToken.value,
          data: {
            sid: _tag.sid,
            jsonString: JSON.stringify(_tag.jsonString),
          },
        };
        await updateMattertag(payload).then(() => {
          const index = mattertagsToBeUpdated.value.indexOf(_tag);
          mattertagsToBeUpdated.value.splice(index, 1);
        });
      });
    }
  );

  function addToMattertagsToBeUpdated(_tag: any) {
    mattertagsToBeUpdated.value.push(_tag);
  }

  function showHelperUi() {
    helperUiOn.value = true;
  }

  function hideHelperUi() {
    helperUiOn.value = false;
  }

  function setObjectAccessibility(
    itemId: string | number,
    accessibility: boolean
  ) {
    objects.value.find((item: any) => item.id === itemId).accessible =
      accessibility;
  }

  function setComponent(objectId: string | number, component: any) {
    components.value[objectId] = component;
  }

  function setSelectedObjectId(objectId: string | number) {
    selectedObjectId.value = objectId;
  }

  return {
    handleShowcaseError,
    openLoadingSpinner,
    closeLoadingSpinner,
    getShowcase,
    showcaseLocked,
    getShowcaseEditor,
    getShowcaseViewer,
    getShowcaseMetadata,
    editShowcaseMetadata,
    updateShowcaseImage,
    addObject,
    duplicateObject,
    loadingSpinner,
    objectsToBeMounted,
    removeObject,
    updateObject,
    addTag,
    addMattertags,
    removeTag,
    updateTag,
    updateTagPosition,
    updateMattertag,
    uploadResource,
    helperUiOn,
    showcaseToken,
    objects,
    setShowcaseToken,
    setObjects,
    numberOfObjectsToBeLoaded,
    setNumberOfObjectsToBeLoaded,
    axisLockedState,
    selectedItem,
    setSelectedItem,
    deselectItem,
    setObjectsToBeMounted,
    initiating,
    turnOffInitiating,
    objectsLoading,
    turnOffObjectsLoading,
    getShowcaseViewerWithPassword,
    getShowcaseViewerWhileLoggedIn,
    currentTransformMode,
    setTransformMode,
    showcaseRenderer,
    setShowcaseRenderer,
    getShowcaseRenderer,
    title,
    tags,
    mattertags,
    setTags,
    tagGetter,
    mattertagsToBeUpdated,
    addToMattertagsToBeUpdated,
    showHelperUi,
    hideHelperUi,
    setObjectAccessibility,
    showcaseInfo,
    components,
    setComponent,
    selectedObjectId,
    setSelectedObjectId,
  };
});
