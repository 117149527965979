import { defineStore } from "pinia";
import { ref } from "vue";
import { alert_messages } from "@/alert_messages";
import { AxiosResponse } from "axios";

export interface AlertPayload {
  type: string;
  closeButtonExist?: boolean;
  disappearAfter?: number;
  response?: AxiosResponse;
  message?: string;
  message_key?: string;
}

const DEFAULT_TYPE = "error";
const DEFAULT_MESSAGE = "An error occurred.";
const DEFAULT_CLOSE_BUTTON_EXIST = true;
const DEFAULT_DISAPPEAR_AFTER = 3000;

export const useAlertStore = defineStore("alert", () => {
  const open = ref(false);
  const type = ref(DEFAULT_TYPE);
  const message = ref(DEFAULT_MESSAGE);
  const closeButtonExist = ref(DEFAULT_CLOSE_BUTTON_EXIST);
  const disappearAfter = ref(DEFAULT_DISAPPEAR_AFTER);

  function openAlert(payload: AlertPayload) {
    type.value = payload.type;
    let message_key = undefined;
    let _message = undefined;
    if (payload.response) {
      if (payload.response.data && payload.response.data.key) {
        message_key = payload.response.data.key;
      }
      _message =
        payload.response.data && payload.response.data.message
          ? payload.response.data.message
          : payload.response.data && !payload.response.data.key
          ? payload.response.data
          : payload.message
          ? payload.message
          : DEFAULT_MESSAGE;
    } else {
      message_key = payload.message_key;
      _message = payload.message;
    }
    if (message_key && message_key in alert_messages) {
      message.value = alert_messages[message_key];
    } else if (_message) {
      message.value = _message;
    } else {
      message.value = DEFAULT_MESSAGE;
    }
    if (payload.closeButtonExist) {
      closeButtonExist.value = payload.closeButtonExist;
    }
    if (payload.disappearAfter) {
      disappearAfter.value = payload.disappearAfter;
    }
    open.value = true;
  }

  function closeAlert() {
    open.value = false;
    type.value = DEFAULT_TYPE;
    message.value = DEFAULT_MESSAGE;
    closeButtonExist.value = DEFAULT_CLOSE_BUTTON_EXIST;
    disappearAfter.value = DEFAULT_DISAPPEAR_AFTER;
  }

  return {
    open,
    type,
    message,
    closeButtonExist,
    disappearAfter,
    openAlert,
    closeAlert,
  };
});
