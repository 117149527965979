import { defineStore, storeToRefs } from "pinia";
import { watch } from "vue";
import axios from "axios";
import { useStorage } from "@vueuse/core";
import router from "../router";
import { useAlertStore } from "@/stores/alert";
import { useBillingStore } from "@/stores/billing";

const API_URL = process.env.VUE_APP_BACKEND_URL + "/auth/";

export const useAuthStore = defineStore("auth", () => {
  const alertStore = useAlertStore();
  const billingStore = useBillingStore();
  const { paymentMethodNeededBy } = storeToRefs(billingStore);
  // TODO: define type
  const user: any = useStorage("user", {});
  const rooomTokenAuthorized = useStorage("rooomTokenAuthorized", true);
  function login(payload: any) {
    axios
      .post(API_URL + "login", payload, {
        withCredentials: true,
      })
      .then((response) => {
        user.value = response.data.user;
        rooomTokenAuthorized.value = response.data.rooom_token_authorized;
        router.push("/user/dashboard");
        if (response.data.payment_method_needed_by) {
          paymentMethodNeededBy.value = response.data.payment_method_needed_by;
        }
      })
      .catch((error) => {
        alertStore.openAlert({
          type: "error",
          response: error.response,
        });
      });
  }

  function authRequestConfig(options?: any) {
    const value = `; ${document.cookie}`;
    const parts = value.split("; csrf_access_token=");
    const cookies = parts.pop()?.split(";").shift();
    let result = {};
    if (cookies) {
      result = { headers: { "X-CSRF-TOKEN": cookies }, withCredentials: true };
    }
    if (options) {
      result = { ...result, ...{ ...options } };
    }
    return result;
  }

  function logout() {
    localStorage.clear();
    location.reload();
    axios
      .post(API_URL + "logout", {}, authRequestConfig())
      .then((response) => {
        user.value = {};
        rooomTokenAuthorized.value = true;
        paymentMethodNeededBy.value = "";
        router.push("/login");
      })
      .catch((error) => {
        // TODO: more things to do when there's an error on logout?
        // console.log("Something went wrong while logging out.");
        user.value = {};
        rooomTokenAuthorized.value = true;
        paymentMethodNeededBy.value = "";
        router.push("/login");
      });
  }

  function resetPassword(payload: { password: string; token: string }) {
    return axios
      .put(
        API_URL + "reset-password",
        { password: payload.password },
        {
          headers: { token: payload.token },
        }
      )
      .then((response) => {
        return response;
      });
  }

  function accessResetPasswordPage(token: string) {
    return axios
      .get(API_URL + "reset-password", {
        headers: { token: token },
      })
      .then((response) => {
        return response;
      });
  }

  function accessConfirmRegistrationPage(token: string) {
    return axios
      .put(
        API_URL + "register",
        {},
        {
          headers: { token: token },
        }
      )
      .then((response) => {
        return response;
      });
  }

  function registerForgotPassword(email: string) {
    return axios
      .post(API_URL + "forgot-password", {
        email: email,
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        alertStore.openAlert({
          type: "error",
          response: error.response,
        });
        return false;
      });
  }

  function register(payload: any) {
    return axios.post(API_URL + "register", payload);
  }

  return {
    user,
    rooomTokenAuthorized,
    login,
    logout,
    authRequestConfig,
    resetPassword,
    accessResetPasswordPage,
    accessConfirmRegistrationPage,
    registerForgotPassword,
    register,
  };
});
