import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from "pinia";
import "./index.css";
import VueSocialSharing from "vue-social-sharing";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createHead } from "@vueuse/head";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserOptions } from "@sentry/browser";
import * as Sentry from "@sentry/vue";

/* add icons to the library */
library.add(faEnvelope, faFacebookF, faLinkedinIn, faTwitter, faWhatsapp);
const pinia = createPinia();
const head = createHead();

const app = createApp(App);

if (process.env.ENVIRONMENT !== "development") {
  Sentry.init({
    app,
    environment: process.env.ENVIRONMENT,
    release: process.env.VUE_APP_RELEASE_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost",
          "https//development.360.rooom.com",
          "https//360.rooom.com",
          /^\//,
        ],
      } as BrowserOptions),
    ],
    debug: process.env.ENVIRONMENT !== "production",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.ENVIRONMENT === "production" ? 0.2 : 1,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: process.env.ENVIRONMENT === "production" ? false : true,
    attachProps: true,
    attachStacktrace: true,
  });
}

app
  .use(router)
  .use(pinia)
  .use(head)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueSocialSharing)
  .mount("#app");
