
import { defineComponent } from "vue";
import { MoonIcon, SunIcon } from "@heroicons/vue/20/solid";

export default defineComponent({
  name: "DarkModeButton",
  components: {
    MoonIcon,
    SunIcon,
  },
  props: ["darkMode"],
  emits: ["toggle"],
});
