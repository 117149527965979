import { defineStore } from "pinia";
import { ref } from "vue";

interface BannerPayload {
  type: string;
  message?: string;
  linkText?: string;
  linkAction?: string;
}

const DEFAULT_TYPE = "warning";
const DEFAULT_MESSAGE = "default message";
const DEFAULT_LINK_TEXT = "default link";
const DEFAULT_LINK_ACTION = "/user/profile";

export const useBannerStore = defineStore("banner", () => {
  const open = ref(false);
  const type = ref(DEFAULT_TYPE);
  const message = ref(DEFAULT_MESSAGE);
  const linkText = ref(DEFAULT_LINK_TEXT);
  const linkAction = ref(DEFAULT_LINK_ACTION);

  function openBanner(payload: BannerPayload) {
    type.value = payload.type;
    message.value = payload.message ? payload.message : "";
    linkText.value = payload.linkText ? payload.linkText : "";
    linkAction.value = payload.linkAction ? payload.linkAction : "";
    open.value = true;
  }

  function closeBanner() {
    open.value = false;
    type.value = DEFAULT_TYPE;
    message.value = DEFAULT_MESSAGE;
    linkText.value = DEFAULT_LINK_TEXT;
    linkAction.value = DEFAULT_LINK_ACTION;
  }

  return {
    open,
    type,
    message,
    linkText,
    linkAction,
    openBanner,
    closeBanner,
  };
});
