
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useWarningPanelStore } from "@/stores/warning_panel";
import {
  ExclamationCircleIcon,
  ArchiveBoxXMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline";

export default defineComponent({
  name: "WarningConfirmationPanel",
  components: {
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    ArchiveBoxXMarkIcon,
  },
  setup() {
    const darkMode = ref(localStorage.getItem("darkMode") === "true");
    const warningPanel = useWarningPanelStore();
    const { open, type, header, message, acceptText, dismissText } =
      storeToRefs(warningPanel);

    function onAccept() {
      warningPanel.accept();
    }

    function onDismiss() {
      warningPanel.dismiss();
    }

    return {
      darkMode,
      open,
      type,
      header,
      message,
      acceptText,
      dismissText,
      onAccept,
      onDismiss,
    };
  },
});
