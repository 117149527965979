import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { AxiosError } from "axios";
import { useAlertStore } from "@/stores/alert";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import { useShowcaseStore } from "@/stores/showcase";

const PROFILE_API_URL = process.env.VUE_APP_BACKEND_URL + "/profile/";
const DASHBOARD_API_URL = process.env.VUE_APP_BACKEND_URL + "/dashboard";

export const useUserStore = defineStore("user", () => {
  const authStore = useAuthStore();
  const alertStore = useAlertStore();
  const showcaseStore = useShowcaseStore();

  const matterportOauthConnected = ref(false);
  const rooomAccountInvalid = ref(false);

  // TODO: define the type
  const profile = ref<any>({});
  const { user } = storeToRefs(authStore);

  function getUserProfile() {
    axios
      .get(PROFILE_API_URL, authStore.authRequestConfig())
      .then((response) => {
        // TODO: shrink backend reseponse json & fix it to profile.value = { ...response.data }
        profile.value.email = response.data.email;
        profile.value.role = response.data.role;
        profile.value.rooomUsername = response.data.rooomUsername;
        profile.value.matterportAPIKeyExists =
          response.data.matterportAPIKeyExists;
        profile.value.rooomToken = response.data.rooomToken;
        // profile.value.matterportOauthConnected = response.data.matterportOauthConnected
      })
      .catch((error) => {
        handleUserError(error);
      });
  }

  function updateUserProfile(update: any) {
    return axios.put(PROFILE_API_URL, update, authStore.authRequestConfig());
  }

  function handleUserError(error: AxiosError) {
    if (error.response?.status === 401) {
      showcaseStore.closeLoadingSpinner();
      alertStore.openAlert({
        type: "error",
        message_key: "JWT_EXPIRED",
      });
      authStore.logout();
    } else {
      // hide the unexpected server errors
      if (error.response) {
        alertStore.openAlert({
          type: "error",
          response: error.response,
        });
      }
    }
  }

  async function sendMatterportOauthCode(code: string) {
    try {
      const response = await axios.post(
        PROFILE_API_URL + "matterport_oauth_callback",
        { code: code },
        authStore.authRequestConfig()
      );
      // connectMatterportPanelOpen.value = false;
      matterportOauthConnected.value = true;
      alertStore.openAlert({
        type: "success",
        message: response.data.message ? response.data.message : response.data,
      });
    } catch (error: any) {
      handleUserError(error);
    }
  }

  function getChangelogs() {
    return axios
      .get(process.env.VUE_APP_BACKEND_URL + "/auth/changelogs")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        handleUserError(error);
      });
  }

  function deleteAccount() {
    return axios
      .delete(PROFILE_API_URL, authStore.authRequestConfig())
      .then((response) => {
        authStore.logout();
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Account successfully deleted.",
        });
        return response;
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  async function checkRooomTokenValidation(rooomToken: string) {
    const authorized = await fetch(
      "https://api.rooom.com/console/me/objects",
      {
        headers: {
          "Authorization": `Bearer ${rooomToken}`
        }  
      }
    )
      .then((_) => {
        return true;
      })
      .catch((_)=>{
        return false
      })
    return authorized;
  }

  function connectToRooom(user: any) {
    return axios.post(
      PROFILE_API_URL + "rooom",
      user,
      authStore.authRequestConfig()
    );
  }

  function connectToMatterport(token: string) {
    return axios.post(
      PROFILE_API_URL + "matterport",
      token,
      authStore.authRequestConfig()
    );
  }

  function removeRooomAccount() {
    return axios
      .delete(PROFILE_API_URL + "rooom", authStore.authRequestConfig())
      .then((response) => {
        alertStore.openAlert({
          type: "success",
          response: response,
          message: "Successfully removed.",
        });
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  function connectToMatterportOAuth() {
    return axios
      .post(
        PROFILE_API_URL + "matterport_oauth",
        null,
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  function removeMatterportConnection() {
    return axios.delete(
      PROFILE_API_URL + "matterport",
      authStore.authRequestConfig()
    );
  }

  function removeMatterportOauthConnection() {
    return axios
      .delete(
        PROFILE_API_URL + "matterport_oauth",
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
        // commit("setMatterportOauthConnected", false);
        // commit(
        //   "openAlert",
        //   {
        //     type: "success",
        //     message: response.data.message
        //       ? response.data.message
        //       : response.data,
        //   },
        //   { root: true }
        // );
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  function getMatterportSdkKey() {
    return axios
      .get(
        DASHBOARD_API_URL + "/matterport-sdk-key",
        authStore.authRequestConfig()
      )
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  function searchShowcaseModel(query: string) {
    return axios
      .get(
        DASHBOARD_API_URL + `/models?q=${query}`,
        authStore.authRequestConfig()
      )
      .then((response) => {
        // if (response.data.results) {
        return response.data;
        // } else {
        //   commit(
        //     "openAlert",
        //     {
        //       type: "error",
        //       message: "An error occurred on model search.",
        //     },
        //     { root: true }
        //   );
        //   throw new Error("An error occurred on model search.");
        // }
      })
      .catch(async (error) => {
        handleUserError(error);
      });
  }

  function deleteShowcase(showcase: any) {
    return axios.delete(
      DASHBOARD_API_URL,
      authStore.authRequestConfig({
        data: showcase,
      })
    );
  }

  function addShowcase(showcase: any) {
    return axios.post(
      DASHBOARD_API_URL,
      showcase,
      authStore.authRequestConfig()
    );
  }

  function getShowcases(query: string, sort: string) {
    return axios
      .get(
        DASHBOARD_API_URL + `?q=${query}&sortBy=${sort}`,
        authStore.authRequestConfig()
      )
      .then(
        (response) => {
          if (response.data.showcases) {
            return response.data.showcases;
          } else {
            // TODO:something maybe should be changed with this..
            alertStore.openAlert({
              type: "error",
              message: "Showcases could not be fetched.",
            });
            return [];
          }
        },
        async (error) => {
          handleUserError(error);
        }
      );
  }

  return {
    handleUserError,
    sendMatterportOauthCode,
    matterportOauthConnected,
    profile,
    getUserProfile,
    updateUserProfile,
    getChangelogs,
    deleteAccount,
    checkRooomTokenValidation,
    connectToRooom,
    connectToMatterport,
    removeRooomAccount,
    connectToMatterportOAuth,
    removeMatterportConnection,
    removeMatterportOauthConnection,
    rooomAccountInvalid,
    getMatterportSdkKey,
    searchShowcaseModel,
    deleteShowcase,
    addShowcase,
    getShowcases,
  };
});
