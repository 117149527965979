<template>
  <div
    v-if="open"
    :class="[
      type === 'warning'
        ? 'bg-yellow-50 border-yellow-400'
        : type === 'error'
        ? 'bg-red-50 border-red-400'
        : 'bg-green-50 border-green-400',
      'border-l-4 p-4 sm:rounded-md sm:left-1/2 sm:transform sm:-translate-x-1/2 fixed top-0 sm:top-5 z-50 w-full sm:w-auto',
    ]"
  >
    <div class="flex">
      <div
        :class="[
          type === 'warning'
            ? 'text-yellow-400'
            : type === 'error'
            ? 'text-red-400'
            : 'text-green-400',
          'flex-shrink-0',
        ]"
      >
        <ExclamationCircleIcon v-if="type === 'warning'" class="h-5 w-5" />
        <XCircleIcon v-if="type === 'error'" class="h-5 w-5" />
        <CheckCircleIcon v-if="type === 'success'" class="h-5 w-5" />
      </div>
      <div class="ml-3">
        <p
          :class="[
            type === 'warning'
              ? 'text-yellow-700'
              : type === 'error'
              ? 'text-red-700'
              : 'text-green-700',
            'text-sm font-medium',
          ]"
        >
          {{ message }}
        </p>
      </div>
      <div v-if="closeButtonExist" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            @click.prevent="closeAlert"
            :class="[
              type === 'warning'
                ? 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600'
                : type === 'error'
                ? 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600'
                : 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
              'inline-flex rounded-md p-1.5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2',
            ]"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { useAlertStore } from "@/stores/alert";
import { storeToRefs } from "pinia";
import {
  XMarkIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/24/solid";

export default defineComponent({
  name: "AppAlert",
  components: {
    XMarkIcon,
    XCircleIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
  },
  setup() {
    const alertStore = useAlertStore();
    const { open, type, message, closeButtonExist, disappearAfter } =
      storeToRefs(alertStore);

    const currentTimeoutId = ref("");

    function closeAlert() {
      alertStore.closeAlert();
    }

    // handle the page reload when redircted from stripe
    onMounted(() => {
      if (message.value && open.value) {
        if (disappearAfter.value > 0) {
          currentTimeoutId.value = setTimeout(() => {
            closeAlert();
          }, disappearAfter.value);
        }
      }
    });

    watch(message, (newMessage) => {
      clearTimeout(currentTimeoutId.value);
      currentTimeoutId.value = "";
      if (newMessage) {
        if (disappearAfter.value > 0) {
          currentTimeoutId.value = setTimeout(() => {
            closeAlert();
          }, disappearAfter.value);
        }
      }
    });

    watch(open, (newOpenValue) => {
      clearTimeout(currentTimeoutId.value);
      currentTimeoutId.value = "";
      if (newOpenValue) {
        if (disappearAfter.value > 0) {
          currentTimeoutId.value = setTimeout(() => {
            closeAlert();
          }, disappearAfter.value);
        }
      }
    });

    return {
      open,
      type,
      message,
      closeButtonExist,
      closeAlert,
    };
  },
});
</script>
