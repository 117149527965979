
import { ref, watch, onUpdated } from "vue";
import { useRouter } from "vue-router";
import ForgotPasswordPanel from "@/views/LoginForgotPasswordPanel.vue";
import { QuestionMarkCircleIcon } from "@heroicons/vue/20/solid";
import { useAuthStore } from "@/stores/auth";
import { useShowcaseStore } from "@/stores/showcase";

export default {
  name: "LoginPage",
  components: {
    ForgotPasswordPanel,
    QuestionMarkCircleIcon,
  },
  setup() {
    const router = useRouter();

    const authStore = useAuthStore();
    const showcaseStore = useShowcaseStore();
    const email = ref("");
    const password = ref("");
    const remember = ref(false);
    const rememberMeDescriptionOpen = ref(false);

    onUpdated(() => {
      showcaseStore.closeLoadingSpinner();
    });

    function goToRegisterPage() {
      router.push("/register");
    }

    watch(remember, (newValue) => {
      console.log(newValue);
    });

    // TODO: add a page for a forgotten password
    function openForgotPasswordPanel() {
      document.querySelector("#forgot-password-panel")?.classList.add("active");
    }

    function onSubmit() {
      if (email.value && password.value) {
        const payload = {
          email: email.value,
          password: password.value,
          remember: remember.value,
        };

        authStore.login(payload);
      }
    }

    function closeForgotPasswordPanel() {
      document
        .querySelector("#forgot-password-panel")
        ?.classList.remove("active");
    }

    return {
      email,
      password,
      remember,
      goToRegisterPage,
      openForgotPasswordPanel,
      onSubmit,
      closeForgotPasswordPanel,
      rememberMeDescriptionOpen,
    };
  },
};
