import { defineStore } from "pinia";
import { ref } from "vue";

interface NotificationPayload {
  type: string;
  title: string;
  message: string;
  linkText: string;
  dismissText: string;
  linkAction: string;
}

const DEFAULT_TYPE = "warning";
const DEFAULT_TITLE = "";
const DEFAULT_MESSAGE = "An error occurred.";
const DEFAULT_LINK_TEXT = "";
const DEFAULT_DISMISS_TEXT = "";
const DEFAULT_LINK_ACTION = "";

export const useNotificationStore = defineStore("notification", () => {
  const open = ref(false);
  const type = ref(DEFAULT_TYPE);
  const title = ref(DEFAULT_TITLE);
  const message = ref(DEFAULT_MESSAGE);
  const linkText = ref(DEFAULT_LINK_TEXT);
  const dismissText = ref(DEFAULT_DISMISS_TEXT);
  const linkAction = ref(DEFAULT_LINK_ACTION);
  const notificationTurnedOff = ref(false);

  function openNotification(payload: NotificationPayload) {
    type.value = payload.type;
    title.value = payload.title;
    message.value = payload.message;
    linkText.value = payload.linkText;
    dismissText.value = payload.dismissText;
    linkAction.value = payload.linkAction;
    open.value = true;
  }

  function closeNotification() {
    open.value = false;
  }

  return {
    open,
    type,
    title,
    message,
    linkText,
    dismissText,
    linkAction,
    openNotification,
    closeNotification,
    notificationTurnedOff,
  };
});
