
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useForm, useField } from "vee-validate";
import * as Yup from "yup";
import { useAlertStore } from "@/stores/alert";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export default {
  name: "RegisterPage",
  setup() {
    const authStore = useAuthStore();
    const alertStore = useAlertStore();
    const router = useRouter();
    const { user } = storeToRefs(authStore);
    if (user.value.id) router.push("/dashboard");

    const successful = ref(false);
    const successMessage = ref("");

    function navigateToLoginPage() {
      router.push("/login");
    }

    // form validation
    const validationSchema = Yup.object({
      email: Yup.string().email().required("email is required"),
      password: Yup.string().min(8).required("password is required"),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "passwords must match"
      ),
    });

    const termsAccepted = ref(false);

    const { meta, resetForm } = useForm({
      validationSchema: validationSchema,
      // initialValues: {
      //   email: '',
      //   password: ''
      // }
    });

    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const {
      value: passwordConfirmation,
      errorMessage: passwordConfirmationError,
    } = useField("passwordConfirmation");

    watch(emailError, (newValue) => {
      if (newValue) {
        document.querySelector('input[name="email"]')?.classList.add("error");
      } else {
        document
          .querySelector('input[name="email"]')
          ?.classList.remove("error");
      }
    });

    watch(passwordError, (newValue) => {
      if (newValue) {
        document
          .querySelector('input[name="password"]')
          ?.classList.add("error");
      } else {
        document
          .querySelector('input[name="password"]')
          ?.classList.remove("error");
      }
    });

    watch(passwordConfirmationError, (newValue) => {
      if (newValue) {
        document
          .querySelector('input[name="passwordConfirmation"]')
          ?.classList.add("error");
      } else {
        document
          .querySelector('input[name="passwordConfirmation"]')
          ?.classList.remove("error");
      }
    });

    function submit() {
      if (meta.value.dirty && !meta.value.valid) {
        return;
      }
      if (!termsAccepted.value) {
        return;
      }
      const user = {
        email: email.value,
        password: password.value,
      };
      authStore
        .register(user)
        .then((response: any) => {
          successful.value = true;
          resetForm();
          successMessage.value = response.data;
        })
        .catch((error) => {
          successful.value = false;
          alertStore.openAlert({
            type: "error",
            response: error.response,
          });
        });
    }

    return {
      meta,
      successful,
      successMessage,
      email,
      emailError,
      password,
      passwordError,
      passwordConfirmation,
      passwordConfirmationError,
      submit,
      navigateToLoginPage,
      termsAccepted,
    };
  },
};
